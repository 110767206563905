import React, {useEffect, useState} from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {mapEdgesToNodes, filterOutDocsWithoutSlugs, getSEOMetadata} from '../lib/helpers'
import BlockContent from '../components/block-content'
import Article from '../components/article'
import ContentSection from '../components/content-section'
import CountUp from 'react-countup'
import GlowStrip from '../components/glow-strip'


export const query = graphql`
  query ColophonePageQuery {
    page: sanityColophonPage {
      title
      _rawTypographyText
      seoFields {
        metaTitle
        metaDescription
        metaImage {
          asset {
            url
          }
        }
      }       
    }  
    about: sanityAboutPage {
      _rawClientsHeadings
      _rawClientsItems
      _rawIntro
      dribbbleUsername
      email
      book
      instagramUsername
      image {
        caption
      }
      _rawRecognitionItems
      _rawRecognitionHeadings
      phone     
    }
    projects: allSanityProject {
      edges {
        node {
          _rawBody
        }
      }
    }
  }
`

const ColophonPage = props => {
  const {data, errors} = props
  const sources = [
    props.data.page,
    props.data.about, 
    props.data.projects
  ]
  const [page, about, projects] = sources;
  const countWords = () => {
    let words = '';
    sources.map((source) => {
      Object.values(source).map(child => {
        const mapper = (child) => {
          if (typeof(child) != 'string' && child != null) {
            if (child.caption) {
              //It's an image
              words += child.caption
            } else if (child[0]._type === 'block') {
              // It's probably a rich text field
              child.map((further) => {
                if (further.children) {
                  further.children.map((blob) => { words += `${blob.text}` })
                }
              })
            }
          } else {
            //It's a single string field
            words += child;
          }
        }
        if (child != null && child[0] && Object.keys(child[0])[0] === 'node') {
          // It's probably a project page
          child.map((node) => {
            Object.values(node.node).map((newNode) => {
              if (newNode != null) {
                mapper(newNode);
              }
            })
          })
        } else {
          mapper(child);
        }
      })
    })
    return words.split(/[\s.]+/).length
  }
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  return (
    <Layout hideGlowStrip={true}>
      <SEO themeColour="purple" SEOMetadata={getSEOMetadata(page)} />
      <Container className="colophon-page">
        <Article>
          <ContentSection>
            <div className="block-content count-block">
              <div>
                <p>There are</p>
                <h4><CountUp delay={0.4} end={countWords()} />*<span className="smaller">words on this website.</span></h4>
              </div>
            </div>
            <BlockContent blocks={page._rawTypographyText || []} className="fonts-block" />
            <div className="block-content ish-block">
              <div>
                <h4>*ish</h4>
              </div>
            </div>
          </ContentSection>
          <GlowStrip />
        </Article>
      </Container>
    </Layout>
  )
}

export default ColophonPage
